
import PlayIcon from '~/assets/icons/play.svg'
import ViewportMixin from '~/mixins/viewport'

export default {
  name: 'Video',
  components: {
    PlayIcon
  },
  mixins: [ViewportMixin],
  props: {
    link: {
      type: Object,
      required: false,
      default: null
    },
    videoAutoplay: {
      type: Boolean,
      required: false,
      default: false
    },
    videoLoop: {
      type: Boolean,
      required: false,
      default: false
    },
    videoControls: {
      type: Boolean,
      required: false,
      default: false
    },
    mobilePoster: {
      type: Object,
      required: false,
      default: () => ({})
    },
    desktopPoster: {
      type: Object,
      required: false,
      default: () => ({})
    },
    mobileVideo: {
      type: String,
      required: false,
      default: ''
    },
    desktopVideo: {
      type: String,
      required: false,
      default: ''
    },
    youtubeVideo: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      videoIsPlaying: false,
      imageBaseUrl: this.$config.imageProxyUrl,
    }
  },
  computed: {
    mobilePosterUrl () {
      if (this.mobilePoster?.filename) {
        return this.$imgproxy.transform(this.mobilePoster?.filename, 'rs:fit:500/g:nowe/q:70')
      }
      return ''
    },
    desktopPosterUrl () {
      if (this.desktopPoster?.filename) {
        return this.$imgproxy.transform(this.desktopPoster?.filename, 'rs:fit:1000/g:nowe/q:70')
      }
      return ''
    }
  },
  unmounted () {
    this.viewportObserver?.disconnect()
  },
  methods: {
    inViewportHandler () {
      if (this.mobileVideo && this.videoAutoplay) {
        this.playVideo(this.$refs.mobileVideo, this.mobileVideo)
      }
      if (this.desktopVideo && this.videoAutoplay) {
        this.playVideo(this.$refs.desktopVideo, this.desktopVideo)
      }
    },
    playVideo (video, src) {
      if (!video) {
        return
      }
      this.videoIsPlaying = true
      const isM3u8 = /\.m3u8/i.test(src)
      if (isM3u8) {
        if (video.canPlayType('application/vnd.apple.mpegurl')) {
          // Native HLS support
          video.src = src
          video.addEventListener('loadedmetadata', video.play)
        } else {
          // Use the HLS polyfill
          function playVideoUsingHlsJs () {
            const hls = new window.Hls()
            hls.loadSource(src)
            hls.attachMedia(video)
            hls.on(window.Hls.Events.MANIFEST_PARSED, () => {
              video.play().catch((err) => { console.error(`Could not play video: ${src}`, err) })
            })
          }
          if (window.Hls) {
            playVideoUsingHlsJs()
          } else {
            const hlsScript = document.createElement('script')
            hlsScript.setAttribute('src', 'https://cdn.jsdelivr.net/npm/hls.js@latest')
            hlsScript.onload = playVideoUsingHlsJs
            document.head.appendChild(hlsScript)
          }
        }
      } else {
        // If the source is something other than .m3u8, just play the video
        video.play().catch((err) => { console.error(`Could not play video: ${src}`, err) })
      }
    },
    isValidVideoSource () {
      return this.mobileVideo.includes('https://player.vimeo.com') || this.desktopVideo.includes('https://player.vimeo.com') || this.mobileVideo.includes('cloudflarestream.com') || this.desktopVideo.includes('cloudflarestream.com')
    }
  }
}
