/**
 * Mixin this and use it to figure out if the current block
 * is visible in the browser window or not
 */
export default {
  data: () => ({
    seenInViewport: false, // Can be used to prevent double GTM tracking for instance
    viewportObserver: undefined,
  }),
  mounted () {
    this.checkIntersection()
  },
  unmounted () {
    this.viewportObserver?.disconnect()
  },
  computed: {
    /**
     * Override this if you want another rootMargin on the intersection observer
     */
    viewportMargin () {
      return 150
    }
  },
  methods: {
    // Check if component's root element is in viewport
    checkIntersection () {
      if (this.seenInViewport || this.viewportObserver) {
        return
      }

      this.viewportObserver = new IntersectionObserver(
        this.handleIntersect,
        { rootMargin: `${this.viewportMargin}px`, threshold: 0.01 },
      )
      this.viewportObserver.observe(this.$el)
    },
    handleIntersect (entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting === true) {
          this.seenInViewport = true
          this.inViewportHandler()
          this.viewportObserver?.unobserve(this.$el)
          this.viewportObserver?.disconnect()
        }
      })
    },
    /**
     * Override this to have it trigger when the component is in the viewport
     */
    inViewportHandler () {}
  }
}
