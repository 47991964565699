
import { mapGetters } from 'vuex'

export default {
  name: 'YoutubeVideo',
  ...mapGetters({
    currentLanguageCode: 'frontend/currentLanguageCode',
  }),
  props: {
    youtubeUrl: {
      type: String,
      required: true,
      default: ''
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false
    },
    async: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data (props) {
    return {
      viewportObserver: null,
      showing: props.autoplay || !props.async,
    }
  },
  computed: {
    embedUrl () {
      const url = new URL(this.youtubeUrl)
      const videoId = url.searchParams.get('v')
      let embeddedUrl
      if (videoId) {
        embeddedUrl = `https://youtube.com/embed/${videoId}?modestbranding=1&rel=0&fs=0&hl=${this.currentLanguageCode}`
      } else if (url.pathname.search('embed') > -1) {
        embeddedUrl = `https://youtube.com${url.pathname}?modestbranding=1&rel=0&fs=0&hl=${this.currentLanguageCode}`
      }
      if (this.autoplay) {
        embeddedUrl += '&autoplay=1'
      }

      return embeddedUrl
    },
    imgUrl () {
      const url = new URL(this.youtubeUrl)
      const videoId = url.searchParams.get('v')
      let embeddedUrl
      if (videoId) {
        embeddedUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
      } else if (url.pathname.search('embed') > -1) {
        embeddedUrl = `https://img.youtube.com/vi/${url.pathname.replace('/embed/', '')}/hqdefault.jpg`
      }

      if (this.autoplay) {
        embeddedUrl += '&autoplay=1'
      }

      return embeddedUrl
    },
  },
  mounted () {
    if (!this.showing) {
      this.checkIntersection()
    }
  },
  unmounted () {
    this.viewportObserver?.disconnect()
  },
  methods: {
    // Check if video is in viewport
    checkIntersection () {
      if (this.showing) {
        return
      }
      if (!this.viewportObserver) {
        this.viewportObserver = new IntersectionObserver(
          this.handleIntersect,
          { rootMargin: '150px', threshold: 0.1 },
        )
      }
      this.viewportObserver.observe(this.$el)
    },
    handleIntersect (entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting === true) {
          this.showing = true
          this.viewportObserver?.unobserve(this.$el)
          this.viewportObserver?.disconnect()
        }
      })
    },
  }
}
